// bootstrap theme overides go here, before the import
$primary: #2b3c58;
$secondary: #f4f5f7;
$light: #e2e2e2;
$white: white;
$grayish: #b1b1b1;
$warning: #8b7c59;
$dark: #252f3f;
// $dark: #282c34;
$splash: #252f3f;

// responsive title
// font-size: calc(2rem + 2*(100vw - 30rem)/30);

// responsive p text
// font-size: calc(1.125rem + 0.375*(100vw - 30rem)/30);
$font-size-base: 1.25rem;
$line-height-base: 1.6;
$headings-font-weight: 100;
$headings-font-family: "Roboto Condensed", sans-serif;

$enable-responsive-font-sizes: true;

$link-letter-spacing: 3px;
$headings-letter-spacing: 8px;
$letter-spacing: 2px;
// $body-color: $secondary;
// $primary: #161e2e;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 993px,
  xxl: 1900px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);
