// bootstrap variable overrides
@import '../../../assets/css/variables.scss';

.splash-screen-container {
  // background-color: #282c34;
  background-color: $splash;
  min-height: 100vh;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  // center horizontal and verticle, display: flex is required
  align-items: center;
  justify-content: center;
  // center horizontal and verticle, display: flex is required

  overflow: hidden;
  position: fixed;
  z-index: 9999;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App-logo {
  opacity: 0;
  width: calc(10px + 25vmin);
  pointer-events: none;
  position: relative;
}
